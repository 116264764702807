import React, { Component, Fragment } from 'react';

import PageStructure from '../../components/PageStructure';
import Spinner from '../../components/Spinner';
import Profile from '../../services/ProfileService';
import RecordsService from '../../services/RecordsService';

import HeaderSection from './components/HeaderSection';
import MapSection from './components/MapSection';
import JobDetails from './components/JobDetails';

class RecordDetails extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.setReturned = this.setReturned.bind(this);
    this.state = {
      profile: '',
      loading: true,
      userRecords: '',
      returned: false,
    };
  }

  setReturned() {
    this.setState({ returned: true });
  }

  getData() {
    Profile.getProfile().then(val => {
      if (val !== '') {
        this.setState({
          profile: val,
        });
      }
    });

    RecordsService.getUserSpecificRecords(this.props.match.params.id).then(
      val => {
        if (val !== '') {
          this.setState({
            userRecords: val,
          });
        }
      }
    );
    const interval = setInterval(() => {
      if (
        this.state.profile !== '' &&
        this.state.profile !== undefined &&
        this.state.userRecords !== ''
      ) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }
  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <Fragment>
        {!this.state.loading ? (
          <PageStructure page="Dashboard" user={this.state.profile}>
            <HeaderSection
              recordId={this.props.match.params.id}
              records={this.state.userRecords.record}
              onClick={this.setReturned}
            />
            <MapSection jobs={this.state.userRecords.job} />
            <JobDetails jobs={this.state.userRecords.job} />
          </PageStructure>
        ) : (
          <Spinner />
        )}
      </Fragment>
    );
  }
}

export default RecordDetails;
