import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import SubmitForm from './SubmitForm';

const styles = theme => ({
  header: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class HeaderSection extends Component {
  constructor(props) {
    super(props);
    this.openDialog = this.openDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.state = {
      open: false,
    };
  }

  openDialog() {
    this.setState({ open: true });
  }

  handleCloseDialog() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div className={this.props.classes.header}>
        <AppBar position="static" color="inherit">
          <Toolbar>
            <IconButton
              edge="start"
              className={this.props.classes.menuButton}
              color="inherit"
              aria-label="menu">
              <InfoIcon color="primary" />
            </IconButton>
            <Typography
              variant="h6"
              className={this.props.classes.title}
              component="div">
              Record Id: {this.props.recordId}
            </Typography>
            <Button
              color="primary"
              disabled={this.props.records.returned === true ? true : false}
              onClick={this.openDialog}>
              {this.props.records.returned === true
                ? 'Record Closed'
                : 'Close Record'}
            </Button>
          </Toolbar>
        </AppBar>
        <Dialog
          open={this.state.open}
          onClose={this.handleCloseDialog}
          fullWidth={true}>
          <DialogTitle> Close Record </DialogTitle>
          <DialogContent>
            <SubmitForm
              classes={this.props.classes}
              recordId={this.props.recordId}
              onClick={this.handleCloseDialog}
              setReturned={this.props.onClick}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(HeaderSection);
