import React, { Component, Fragment } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import Title from '../../../../components/Title';
import Spinner from '../../../../components/Spinner';
import RecordsService from '../../../../services/RecordsService';

const styles = theme => ({
  content: {
    padding: '10px',
    margin: '10px',
    textAlign: 'center',
  },
});

class JobStats extends Component {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/';

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      jobData: '',
    };
  }

  getJobStats() {
    RecordsService.getJobStats().then(val => {
      if (val !== '') {
        const refactoredData = [];
        val.map(entry => {
          const data = {
            name: new Date(entry.date).toLocaleDateString(),
            jobs: entry.jobCount,
          };
          refactoredData.push(data);
          return refactoredData;
        });
        this.setState({
          jobData: refactoredData,
        });
      }
    });
    const interval = setInterval(() => {
      if (this.state.jobData !== '' && this.state.jobData !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }

  componentDidMount() {
    this.getJobStats();
  }
  render() {
    return (
      <Fragment>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className={this.props.classes.content}>
            <Title>Number of Jobs over time</Title>
            <LineChart
              width={500}
              height={300}
              data={this.state.jobData}
              margin={{
                top: 5,
                right: 30,
                left: 100,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="jobs"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </div>
        )}
      </Fragment>
    );
  }
}
export default withStyles(styles)(JobStats);
