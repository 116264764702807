import axios from 'axios';
import Auth from '../AuthService';

const getJobs = async () => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/jobs';
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

export { getJobs };
