import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withFormik } from 'formik';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import Profile from '../../../services/ProfileService';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  addCount() {
    this.setState({ count: 1 });
  }
  resetCount() {
    this.setState({ count: 0 });
  }

  handleSubmit(data) {
    Profile.resetPassword(data)
      .then(output => {
        this.props.onBlur();
        this.props.onClick();
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 400') {
          this.props.onChange('Failed to update password, check your input');
          this.props.onClick();
        } else {
          this.props.onChange(error.message);
          this.props.onClick();
        }
      });
  }

  componentDidUpdate() {
    if (this.state.count === 0) {
      if (this.props.status !== undefined) {
        if (this.props.status.data !== undefined) {
          this.addCount();
          this.handleSubmit(this.props.status.data);
        }
      }
    }
  }

  render() {
    return (
      <div>
        <form onSubmit={this.props.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="currentPassword"
            name="currentPassword"
            label="Current Password"
            autoComplete="current password"
            type="password"
            value={this.props.values.currentPassword || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            error={
              this.props.touched.currentPassword &&
              this.props.errors.currentPassword
                ? true
                : false
            }
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="newPassword"
            name="newPassword"
            label="New Password"
            autoComplete="New Password"
            type="password"
            value={this.props.values.newPassword || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            error={
              this.props.touched.newPassword && this.props.errors.newPassword
                ? true
                : false
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="repeatPassword"
            name="repeatPassword"
            label="Confirm New Password"
            autoComplete="repeatPassword"
            type="password"
            value={this.props.values.repeatPassword || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            error={
              this.props.touched.repeatPassword &&
              this.props.errors.repeatPassword
                ? true
                : false
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={this.props.classes.submit}
            disabled={this.props.errors.email ? true : false}>
            Submit
          </Button>
        </form>
      </div>
    );
  }
}

export default withFormik({
  mapPropsToValues: () => ({
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  }),
  validationSchema: Yup.object().shape({
    currentPassword: Yup.string().required('repeat!!'),
    newPassword: Yup.string().required('repeat!!'),
    repeatPassword: Yup.string().required('repeat!!'),
  }),
  handleSubmit: (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    setStatus({ data: values });
  },
})(ResetPassword);
