import React, { Component, Fragment } from 'react';
import MaterialTable from 'material-table';
import Spinner from '../../../components/Spinner';
import UserService from '../../../services/UserService';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';

class Technicians extends Component {
  constructor(props) {
    super(props);
    this.getTechnicians = this.getTechnicians.bind(this);
    this.state = {
      data: '',
      loading: true,
      id: '',
    };
  }

  getTechnicians() {
    UserService.getTechnicians().then(val => {
      if (val !== '') {
        const values = [];
        val.map(user => {
          const entry = {
            id: user.id,
            email: user.email,
            fName: user.firstName,
            lName: user.lastName,
            number: user.phoneNumber,
            role:
              user.role === 'ADMIN' ? 1 : user.role === 'TECHNICIAN' ? 2 : 3,
          };
          values.push(entry);
          return values;
        });
        this.setState({ data: values });
      }
    });

    const interval = setInterval(() => {
      if (this.state.data !== '' && this.state.data !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }

  componentDidMount() {
    this.getTechnicians();
  }
  render() {
    const columns = [
      { title: 'Id', field: 'id', editable: 'never' },
      { title: 'Email', field: 'email' },
      { title: 'First Name', field: 'fName' },
      { title: 'Last Name', field: 'lName' },
      { title: 'Phone Number', field: 'number' },
      {
        title: 'Role',
        field: 'role',
        lookup: { 1: 'ADMIN', 2: 'TECHNICIAN', 3: 'SECURITY' },
      },
    ];
    return (
      <Fragment>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <MaterialTable
            title="Available Technicians"
            columns={columns}
            data={this.state.data}
            actions={[
              rowData => ({
                icon:
                  this.props.user !== '' && this.props.user.id === rowData.id
                    ? () => <ToggleOnIcon color="secondary" size="medium" />
                    : () => <ToggleOffIcon color="primary" size="medium" />,
                tooltip: 'Select User',
                onClick: (event, rowData) => this.props.onClick(rowData),
              }),
            ]}
          />
        )}
      </Fragment>
    );
  }
}

export default Technicians;

/*
options={{
              selection: true,
              selectionProps: rowData => ({
                disabled: rowData.id === 9,
                color: "primary",
                selected: rowData.id===8,
                defaultChecked: true
              }),
            }}
*/
