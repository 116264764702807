import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Auth from '../../services/AuthService';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const authenticated = Auth.isLoggedIn();
        if (authenticated) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
