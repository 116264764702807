import React, { Component, Fragment } from 'react';

import PageStructure from '../../components/PageStructure';
import Spinner from '../../components/Spinner';
import Profile from '../../services/ProfileService';
import Apks from './components/Apks';

class Downloads extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.state = {
      profile: '',
      loading: true,
    };
  }

  getProfile() {
    Profile.getProfile().then(val => {
      if (val !== '') {
        this.setState({
          profile: val,
        });
      }
    });
    const interval = setInterval(() => {
      if (this.state.profile !== '' && this.state.profile !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }
  componentDidMount() {
    this.getProfile();
  }

  render() {
    return (
      <Fragment>
        {!this.state.loading ? (
          <PageStructure page="Downloads" user={this.state.profile}>
            <Apks profile={this.state.profile} />
          </PageStructure>
        ) : (
          <Spinner />
        )}
      </Fragment>
    );
  }
}

export default Downloads;
