import axios from 'axios';
import Auth from '../AuthService';

const getAllResources = async () => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/storage/';
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      console.log(res);
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const uploadApk = async file => {
  const formData = new FormData();
  let data = '';
  formData.append('file', file);
  const url = 'https://splicing.adtel.co.ke/api/storage/upload-apk';
  const token = localStorage.getItem('token');
  await axios
    .post(url, formData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return data;
      }
      alert(error.message);
    });

  return data;
};

const downloadApk = async filename => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/storage/download/${filename}`;
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'apk.zip');
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
  return data;
};

export { getAllResources, uploadApk, downloadApk };
