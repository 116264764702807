import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AvailableMachines from './AvailableMachines';
import Technicians from './Technicians';
import SelectOtdr from './SelectOtdr';
import RecordsService from '../../../services/RecordsService';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Chip from '@material-ui/core/Chip';
import Title from '../../../components/Title';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { green } from '@material-ui/core/colors';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    overflow: 'auto',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  panel: {
    width: '100%',
  },
  list: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  rounded: {
    color: '#fff',
    backgroundColor: green[500],
  },
});

class IssueSteps extends Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.setUser = this.setUser.bind(this);
    this.setMachine = this.setMachine.bind(this);
    this.setSpliceCount = this.setSpliceCount.bind(this);
    this.setSelectedMachine = this.setSelectedMachine.bind(this);
    this.setSelectedUser = this.setSelectedUser.bind(this);
    this.setSelectedOTDRMachine = this.setSelectedOTDRMachine.bind(this);
    this.handleIssue = this.handleIssue.bind(this);
    this.reset = this.reset.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      activeStep: 0,
      selectedUser: '',
      selectedMachine: '',
      selectedOTDRMachine: '',
      currentSpliceCount: '',
    };
  }

  reset() {
    this.setState({
      selectedUser: '',
      activeStep: 0,
      selectedMachine: '',
      currentSpliceCount: '',
      selectedOTDRMachine: '',
      issuedData: '',
    });
  }
  setSelectedOTDRMachine(machine) {
    if (this.state.selectedOTDRMachine !== '') {
      if (this.state.selectedOTDRMachine.id === machine.id) {
        this.setState({ selectedOTDRMachine: '' });
      } else {
        this.setState({ selectedOTDRMachine: machine });
      }
    } else {
      this.setState({ selectedOTDRMachine: machine });
    }
  }

  setSelectedMachine(machine) {
    if (this.state.selectedMachine !== '') {
      if (this.state.selectedMachine.id === machine.id) {
        this.setState({ selectedMachine: '' });
      } else {
        this.setState({ selectedMachine: machine });
      }
    } else {
      this.setState({ selectedMachine: machine });
    }
  }

  setSelectedUser(user) {
    if (this.state.selectedUser !== '') {
      if (this.state.selectedUser.id === user.id) {
        this.setState({ selectedUser: '' });
      } else {
        this.setState({ selectedUser: user });
      }
    } else {
      this.setState({ selectedUser: user });
    }
  }

  setUser(user) {
    this.setState({ selectedUser: user });
  }

  setMachine(machine) {
    this.setState({ selectedMachine: machine });
  }

  setSpliceCount(count) {
    this.setState({ currentSpliceCount: count });
  }

  handleNext() {
    const activeStep = this.state.activeStep;
    if (activeStep < 4) {
      this.setState({ activeStep: activeStep + 1 });
    }
  }

  handleBack() {
    const activeStep = this.state.activeStep;
    if (activeStep > 0) {
      this.setState({ activeStep: activeStep - 1 });
    }
  }

  handleChange(e) {
    const val = e.target.value;
    this.setState({ currentSpliceCount: val });
  }

  handleIssue() {
    RecordsService.issueDevice(
      this.state.selectedUser,
      this.state.selectedMachine,
      this.state.currentSpliceCount,
      this.state.selectedOTDRMachine
    ).then(val => {
      if (val !== '') {
        this.setState({ issuedData: val });
      }
      this.handleNext();
    });
  }
  render() {
    const steps = [
      'Select Technician',
      'Select Splice Machine',
      'Select OTDR Machine',
      'Review',
    ];
    const valid =
      this.state.selectedMachine !== '' &&
      this.state.selectedUser !== '' &&
      this.state.currentSpliceCount !== '';
    return (
      <Paper className={this.props.classes.paper}>
        <Typography component="h2" variant="h5" align="center" color="primary">
          Issue
        </Typography>
        <Stepper
          activeStep={this.state.activeStep}
          className={this.props.classes.stepper}>
          <Step>
            <StepLabel>
              <Badge badgeContent="*">Select Technician</Badge>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Badge badgeContent="*">Select Splice Machine</Badge>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>Select OTDR Machine</StepLabel>
          </Step>
          <Step>
            <StepLabel>Review</StepLabel>
          </Step>
        </Stepper>
        {this.state.activeStep === steps.length ? (
          <Fragment>
            <Typography
              variant="h3"
              gutterBottom
              component="div"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
              }}>
              <div style={{ margin: '10px', padding: '10px' }}>
                <Avatar
                  variant="rounded"
                  className={this.props.classes.rounded}>
                  <DoneOutlineIcon />
                </Avatar>
              </div>
              <div style={{ margin: '10px 10px', padding: '10px' }}>
                Success
              </div>
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={this.reset}
              className={this.props.classes.button}>
              Again
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            {this.state.activeStep === 0 && (
              <Technicians
                user={this.state.selectedUser}
                onClick={this.setSelectedUser}
              />
            )}
            {this.state.activeStep === 1 && (
              <AvailableMachines
                machine={this.state.selectedMachine}
                onClick={this.setSelectedMachine}
              />
            )}
            {this.state.activeStep === 2 && (
              <SelectOtdr
                machine={this.state.selectedOTDRMachine}
                onClick={this.setSelectedOTDRMachine}
              />
            )}

            {this.state.activeStep === 3 && (
              <Typography component="div">
                <Title>Finishing Up</Title>
                <div className={this.props.classes.panel}>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        className={this.props.classes.heading}
                        component="div">
                        Technician:{' '}
                        {this.state.selectedUser.fName === undefined ? (
                          <Chip label="None Selected" color="secondary" />
                        ) : (
                          <Chip
                            label={`${this.state.selectedUser.fName} ${this.state.selectedUser.lName}`}
                            color="primary"
                          />
                        )}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails component="div">
                      <List className={this.props.classes.list} component="nav">
                        <ListItem>
                          <ListItemIcon>
                            <AlternateEmailIcon />
                          </ListItemIcon>
                          <ListItemText>
                            {this.state.selectedUser.email}
                          </ListItemText>
                        </ListItem>

                        <ListItem>
                          <ListItemIcon>
                            <PhoneIcon />
                          </ListItemIcon>
                          <ListItemText>
                            {this.state.selectedUser.number}
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        className={this.props.classes.heading}
                        component="div">
                        Splicing Machine:{' '}
                        {this.state.selectedMachine.model === undefined ? (
                          <Chip label="None Selected" color="secondary" />
                        ) : (
                          <Chip
                            label={`${this.state.selectedMachine.model} SN:${this.state.selectedMachine.serialNumber}`}
                            color="primary"
                          />
                        )}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails component="div">
                      <List className={this.props.classes.list} component="nav">
                        <ListItem>
                          <ListItemIcon>
                            <EventAvailableIcon />
                          </ListItemIcon>
                          <ListItemText>
                            {this.state.selectedMachine.available
                              ? 'Available for issue'
                              : 'Not Available for Issue'}
                          </ListItemText>
                        </ListItem>
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        className={this.props.classes.heading}
                        component="div">
                        OTDR Machine:{' '}
                        {this.state.selectedOTDRMachine.model === undefined ? (
                          <Chip label="None Selected" />
                        ) : (
                          <Chip
                            label={`${this.state.selectedOTDRMachine.model} SN:${this.state.selectedOTDRMachine.serialNumber}`}
                            color="primary"
                          />
                        )}
                      </Typography>
                    </ExpansionPanelSummary>
                  </ExpansionPanel>
                </div>
                <TextField
                  type="text"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Current Splice Count"
                  autoComplete="splice count"
                  onChange={e => this.handleChange(e)}
                />
              </Typography>
            )}
            <div className={this.props.classes.buttons}>
              {this.state.activeStep !== 0 && (
                <Button
                  onClick={this.handleBack}
                  className={this.props.classes.button}>
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                disabled={
                  this.state.activeStep === steps.length - 1 && valid === false
                    ? true
                    : false
                }
                onClick={
                  this.state.activeStep === steps.length - 1
                    ? this.handleIssue
                    : this.handleNext
                }
                className={this.props.classes.button}>
                {this.state.activeStep === steps.length - 1
                  ? 'Issue Device'
                  : 'Next'}
              </Button>
            </div>
          </Fragment>
        )}
      </Paper>
    );
  }
}

export default withStyles(styles)(IssueSteps);
