import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  spinner: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
});

const Spinner = props => {
  return (
    <div className={props.classes.spinner}>
      <CircularProgress />
    </div>
  );
};

export default withStyles(styles)(Spinner);
