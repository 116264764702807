import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Spinner from '../../../../components/Spinner';
import { withStyles } from '@material-ui/core/styles';
import Title from '../../../../components/Title';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { DropzoneDialog } from 'material-ui-dropzone';
import IconButton from '@material-ui/core/IconButton';
import Resources from '../../../../services/ResourceService';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import AddBoxIcon from '@material-ui/icons/AddBox';

const Transition = props => {
  return <Slide {...props} direction="left" />;
};

const styles = theme => ({
  panel: {
    width: '100%',
    padding: theme.spacing(3, 2),
  },
  list: {
    width: '100%',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

class Apks extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.getResources = this.getResources.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.setSubmitted = this.setSubmitted.bind(this);
    this.closeSubmitted = this.closeSubmitted.bind(this);
    this.state = {
      data: '',
      open: false,
      loading: true,
      submitted: false,
      upload: '',
      submitting: false,
    };
  }

  setSubmitted() {
    this.setState({ submitted: true });
  }

  closeSubmitted() {
    this.setState({ submitted: false, submitting: false });
    window.location.reload(true);
  }

  setOpen(val) {
    this.setState({ open: val });
  }

  handleChange(files) {
    this.setState({
      files: files,
    });
  }

  getResources() {
    Resources.getAllResources().then(val => {
      if (val !== '') {
        this.setState({ data: val });
      }
      return val;
    });

    const interval = setInterval(() => {
      if (this.state.data !== '' && this.state.data !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }

  handleFileUpload(files) {
    this.setState({ submitting: true, open: false });
    Resources.uploadApk(files[0]).then(val => {
      if (val !== '') {
        this.setState({ upload: val });
      }
    });

    const interval = setInterval(() => {
      if (this.state.upload !== '' && this.state.upload !== undefined) {
        this.setState({
          submitted: true,
        });
        clearInterval(interval);
      }
    }, 100);
  }

  componentDidMount() {
    this.getResources();
  }
  render() {
    return (
      <Fragment>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={12}>
            {this.state.loading === true ? (
              <Spinner />
            ) : (
              <Paper className={this.props.classes.panel}>
                <div className={this.props.classes.header}>
                  <Title>Available Downloads</Title>
                  {this.props.profile.role === 'ADMIN' ||
                  this.props.profile.role === 'SUPERADMIN' ? (
                    <IconButton
                      color="primary"
                      variant="outlined"
                      size="large"
                      onClick={() => this.setOpen(true)}>
                      <AddBoxIcon />
                    </IconButton>
                  ) : null}
                </div>
                <List component="nav" className={this.props.classes.list}>
                  {this.state.data.map(val => {
                    return (
                      <ListItem key={val}>
                        <ListItemIcon>
                          <a
                            href={`https://splicing.adtel.co.ke/api/storage/download/${
                              val.split('/')[val.split('/').length - 1]
                            }`}
                            download
                            target="_blank"
                            rel="noopener noreferrer">
                            <CloudDownloadIcon />
                          </a>
                        </ListItemIcon>
                        <ListItemText>
                          {val.split('/')[val.split('/').length - 1]}
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            )}
          </Grid>
        </Grid>
        <DropzoneDialog
          acceptedFiles={['application/*']}
          open={this.state.open}
          cancelButtonText={'cancel'}
          submitButtonText={'submit'}
          maxFileSize={50000000}
          onClose={() => this.setOpen(false)}
          onSave={files => this.handleFileUpload(files)}
          showPreviews={false}
          showFileNames={true}
          showPreviewsInDropzone={true}
          filesLimit={1}></DropzoneDialog>
        {this.state.submitting === true && this.state.submitted === false ? (
          <Spinner />
        ) : null}
        <Snackbar
          open={this.state.submitted}
          autoHideDuration={6000}
          onClose={this.closeSubmitted}
          TransitionComponent={Transition}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
          <Alert severity="success" onClose={this.handleClose}>
            File Upload Successful
          </Alert>
        </Snackbar>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Apks);
