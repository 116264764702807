import axios from 'axios';
import Auth from '../AuthService';

const getRegions = async () => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/region';
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const deleteRegion = async region => {
  const url = `https://splicing.adtel.co.ke/api/region/${region.id}`;
  const token = localStorage.getItem('token');
  await axios
    .delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
};

const editRegion = async region => {
  const url = `https://splicing.adtel.co.ke/api/region/${region.id}`;
  const token = localStorage.getItem('token');
  await axios
    .put(
      url,
      {
        regionId: region.regId,
        name: region.regName,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
};

const addRegion = async region => {
  const url = `https://splicing.adtel.co.ke/api/region`;
  const token = localStorage.getItem('token');
  await axios
    .post(
      url,
      {
        regionId: region.regId,
        name: region.regName,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
};

export { getRegions, deleteRegion, editRegion, addRegion };
