import axios from 'axios';
import Auth from '../AuthService';

const getMachines = async () => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/machines';
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const addMachine = async (machine, regions) => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/machines';
  const token = localStorage.getItem('token');
  await axios
    .post(
      url,
      {
        model: machine.model,
        serialNumber: machine.serialNumber,
        region:
          machine.region === 'Null'
            ? null
            : regions.filter(region => region.regionId === machine.region)[0],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const editMachine = async (machine, regions) => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/machines/${machine.id}`;
  const token = localStorage.getItem('token');
  await axios
    .put(
      url,
      {
        model: machine.model,
        serialNumber: machine.serialNumber,
        region:
          machine.region === 'Null'
            ? null
            : regions.filter(region => region.regionId === machine.region)[0],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const deleteMachine = async machine => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/machines/${machine.id}`;
  const token = localStorage.getItem('token');
  await axios
    .delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const getAvailableMachines = async () => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/machines/available';
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      params: { issued: true },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

//otdr machines
const getOtdrMachines = async () => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/otdr';
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const deleteOtdrMachine = async machine => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/otdr/${machine.id}`;
  const token = localStorage.getItem('token');
  await axios
    .delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const editOtdrMachine = async (machine, regions) => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/otdr/${machine.id}`;
  const token = localStorage.getItem('token');
  await axios
    .put(
      url,
      {
        model: machine.model,
        serialNumber: machine.serialNumber,
        region:
          machine.region === 'Null'
            ? null
            : regions.filter(region => region.regionId === machine.region)[0],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const addOtdrMachine = async (machine, regions) => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/otdr';
  const token = localStorage.getItem('token');
  await axios
    .post(
      url,
      {
        model: machine.model,
        serialNumber: machine.serialNumber,
        region:
          machine.region === 'Null'
            ? null
            : regions.filter(region => region.regionId === machine.region)[0],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const getAvailableOtdrMachines = async () => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/otdr/available';
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      params: { issued: true },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

export {
  addMachine,
  editMachine,
  deleteMachine,
  getMachines,
  getAvailableMachines,
  getOtdrMachines,
  deleteOtdrMachine,
  editOtdrMachine,
  addOtdrMachine,
  getAvailableOtdrMachines,
};
