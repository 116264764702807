import axios from 'axios';
import Auth from '../AuthService';

const getRecords = async () => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/records`;
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
  return data;
};

const issueDevice = async (
  issuedTo,
  issuedMachine,
  spliceCount,
  issuedOTDR
) => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/records`;
  const token = localStorage.getItem('token');
  await axios
    .post(
      url,
      {
        machine: {
          id: issuedMachine.id,
          model: issuedMachine.model,
          serialNumber: issuedMachine.serialNumber,
        },
        issuedTo: {
          id: issuedTo.id,
          email: issuedTo.email,
          phoneNumber: issuedTo.phoneNumber,
          role: issuedTo.role,
        },
        spliceCount: spliceCount,
        otdrMachine:
          issuedOTDR === ''
            ? null
            : {
                id: issuedOTDR.id,
                model: issuedOTDR.model,
                serialNumber: issuedOTDR.serialNumber,
              },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
  return data;
};

const getDeviceStats = async () => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/report/device-stats`;
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
  return data;
};

const getJobStats = async () => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/report/job-stats`;
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
  return data;
};

const getActiveRecords = async () => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/records/active`;
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
  return data;
};

const getUserSpecificRecords = async id => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/records/${id}/jobs`;
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
  return data;
};

const returnDevice = async (recordId, spliceCount) => {
  let data = '';
  const url = `https://splicing.adtel.co.ke/api/records/return/${recordId}`;
  const token = localStorage.getItem('token');
  await axios
    .put(
      url,
      {},
      {
        params: {
          spliceCount: spliceCount,
        },

        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
  return data;
};

export {
  getRecords,
  issueDevice,
  getDeviceStats,
  getJobStats,
  getActiveRecords,
  getUserSpecificRecords,
  returnDevice,
};
