import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import PageStructure from '../../components/PageStructure';
import Spinner from '../../components/Spinner';
import Profile from '../../services/ProfileService';
import Title from '../../components/Title';

import UsersList from './components/UsersList';

const styles = theme => ({});

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.state = {
      profile: '',
      loading: true,
    };
  }

  getProfile() {
    Profile.getProfile().then(val => {
      if (val !== '') {
        this.setState({
          profile: val,
        });
      }
    });
    const interval = setInterval(() => {
      if (this.state.profile !== '' && this.state.profile !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }
  componentDidMount() {
    this.getProfile();
  }

  render() {
    return (
      <Fragment>
        {!this.state.loading ? (
          <PageStructure page="Accounts" user={this.state.profile}>
            <CssBaseline />
            <Title>Manage User Accounts</Title>
            <UsersList profile={this.state.profile} />
          </PageStructure>
        ) : (
          <Spinner />
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(Accounts);
