import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withFormik } from 'formik';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import Profile from '../../services/ProfileService';

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  addCount() {
    this.setState({ count: 1 });
  }
  resetCount() {
    this.setState({ count: 0 });
  }

  handleSubmit(data) {
    Profile.forgotPassword(data)
      .then(output => {
        this.props.onBlur();
        this.props.onClick();
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 400') {
          this.props.onChange(
            'Change Password Rejected, unrecognized email address'
          );
          this.props.onClick();
        } else {
          this.props.onChange(error.message);
          this.props.onClick();
        }
      });
  }

  componentDidUpdate() {
    if (this.state.count === 0) {
      if (this.props.status !== undefined) {
        if (this.props.status.data !== undefined) {
          this.addCount();
          this.handleSubmit(this.props.status.data.email);
        }
      }
    }
  }

  render() {
    return (
      <div>
        <form onSubmit={this.props.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            label="Current Email Address"
            autoComplete="email"
            type="email"
            value={this.props.values.email || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            error={
              this.props.touched.email && this.props.errors.email ? true : false
            }
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={this.props.classes.submit}
            disabled={this.props.errors.email ? true : false}>
            Submit
          </Button>
        </form>
      </div>
    );
  }
}

export default withFormik({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string().required(
      'enter email address associated with the account'
    ),
  }),
  handleSubmit: (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    setStatus({ data: values });
  },
})(ForgotPasswordForm);
