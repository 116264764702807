import React, { Component } from 'react';
import { withFormik } from 'formik';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Profile from '../../../services/ProfileService';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';

const Transition = props => {
  return <Slide {...props} direction="left" />;
};

class UpdateForm extends Component {
  constructor(props) {
    super(props);
    this.addCount = this.addCount.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setError = this.setError.bind(this);
    this.setSuccess = this.setSuccess.bind(this);
    this.state = {
      count: 0,
      error: false,
      success: false,
      errorMessage: '',
    };
  }

  setError(message) {
    this.setState({ error: true, errorMessage: message });
  }
  setSuccess() {
    this.setState({ success: true });
  }

  addCount() {
    this.setState({ count: 1 });
  }

  handleClose() {
    this.setState({
      success: false,
      error: false,
      message: '',
    });
  }

  handleSubmit(data) {
    Profile.updateProfile(data)
      .then(output => {
        this.setSuccess();
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 400') {
          this.setError('An error occured');
        } else {
          this.props.onChange(error.message);
        }
      });
  }

  componentDidUpdate() {
    if (this.state.count === 0) {
      if (this.props.status !== undefined) {
        if (this.props.status.data !== undefined) {
          this.addCount();
          this.handleSubmit(this.props.status.data);
        }
      }
    }
  }
  render() {
    return (
      <Paper className={this.props.classes.paper}>
        <form onSubmit={this.props.handleSubmit}>
          <FormControl
            disabled={true}
            fullWidth={true}
            className={this.props.classes.formControl}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              id="email"
              name="email"
              aria-describedby="my-helper-text"
              value={this.props.values.email || ''}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
            />
            <FormHelperText id="my-helper-text">Update Email</FormHelperText>
          </FormControl>

          <FormControl
            fullWidth={true}
            className={this.props.classes.formControl}>
            <InputLabel htmlFor="fName">First Name</InputLabel>
            <Input
              id="fName"
              name="fName"
              aria-describedby="my-helper-text"
              value={this.props.values.fName || ''}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
            />
            <FormHelperText id="my-helper-text">
              Update First Name
            </FormHelperText>
          </FormControl>

          <FormControl
            fullWidth={true}
            className={this.props.classes.formControl}>
            <InputLabel htmlFor="lName">Last Name</InputLabel>
            <Input
              id="lName"
              name="lName"
              aria-describedby="my-helper-text"
              value={this.props.values.lName || ''}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
            />
            <FormHelperText id="my-helper-text">
              Update Last Name
            </FormHelperText>
          </FormControl>

          <FormControl
            fullWidth={true}
            className={this.props.classes.formControl}>
            <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
            <Input
              id="phoneNumber"
              name="phoneNumber"
              aria-describedby="my-helper-text"
              value={this.props.values.phoneNumber || ''}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
            />
            <FormHelperText id="my-helper-text">
              Update Phone Number
            </FormHelperText>
          </FormControl>

          <FormControl
            fullWidth={true}
            className={this.props.classes.formControl}
            disabled={true}>
            <InputLabel htmlFor="role">Role</InputLabel>
            <Input
              id="role"
              name="role"
              aria-describedby="my-helper-text"
              value={this.props.values.role || ''}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
            />
            <FormHelperText id="my-helper-text">Role</FormHelperText>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={this.props.classes.submit}>
            Update Details
          </Button>
        </form>
        <Snackbar
          open={this.state.error}
          autoHideDuration={6000}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
          <Alert severity="error" onClose={this.handleClose}>
            {this.state.errorMessage}
          </Alert>
        </Snackbar>

        <Snackbar
          open={this.state.success}
          autoHideDuration={6000}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
          <Alert severity="success" onClose={this.handleClose}>
            Update Successful
          </Alert>
        </Snackbar>
      </Paper>
    );
  }
}
export default withFormik({
  mapPropsToValues: props => ({
    email: props.user.email,
    fName: props.user.firstName,
    lName: props.user.lastName,
    phoneNumber: props.user.phoneNumber,
    role: props.user.role,
  }),
  handleSubmit: (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    setStatus({ data: values });
  },
})(UpdateForm);
