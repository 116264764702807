import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import MachineService from '../../../services/MachineService';
import Spinner from '../../../components/Spinner';
import RegionService from '../../../services/RegionsService';

const styles = theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    marginTop: 10,
    overflowX: 'auto',
    minWidth: '350px',
  },
});

class ListOtdrs extends Component {
  constructor(props) {
    super(props);
    this.getMachines = this.getMachines.bind(this);
    this.state = {
      data: '',
      loading: true,
      regions: '',
    };
  }

  getMachines(regions) {
    MachineService.getOtdrMachines().then(val => {
      if (val !== '') {
        const values = [];
        val.map(machine => {
          const entry = {
            id: machine.id,
            model: machine.model,
            serialNumber: machine.serialNumber,
            available: machine.available ? 'In Store' : 'Field',
            region: machine.region === null ? 'Null' : machine.region.regionId,
            created: new Date(machine.created).toLocaleDateString(),
            regionObj: machine.region,
          };
          values.push(entry);
          return values;
        });
        this.setState({ data: values });
      }
    });

    const interval = setInterval(() => {
      if (this.state.data !== '' && this.state.data !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }

  componentDidMount() {
    RegionService.getRegions()
      .then(data => {
        this.setState({ regions: data });
        this.getMachines(data);
      })
      .catch(err => {
        console.error(err.message);
      });
    this.getMachines();
  }

  render() {
    let columns;
    if (this.state.regions !== '') {
      const lookup = {};
      this.state.regions.map(region => {
        lookup[region.regionId] = region.name;
        lookup['Null'] = 'None selected';
        return '';
      });
      columns = [
        { title: 'Model', field: 'model' },
        { title: 'Serial Number', field: 'serialNumber' },
        { title: 'Availability', field: 'available', editable: 'never' },
        {
          title: 'Region',
          field: 'region',
          lookup: lookup,
        },
        { title: 'Created', field: 'created', editable: 'never' },
      ];
    } else {
      columns = [
        { title: 'Model', field: 'model' },
        { title: 'Serial Number', field: 'serialNumber' },
        { title: 'Availability', field: 'available', editable: 'never' },
        { title: 'Created', field: 'created', editable: 'never' },
      ];
    }

    return (
      <Fragment>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <MaterialTable
            className={this.props.classes.paper}
            title="OTDR Machines"
            columns={columns}
            data={this.state.data}
            editable={
              this.props.profile.role === 'SUPERADMIN' ||
              this.props.profile.role === 'ADMIN' ||
              this.props.profile.role === 'MAKER'
                ? {
                    onRowUpdate: (newData, oldData) =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          if (oldData.email !== this.props.profile.email) {
                            MachineService.editOtdrMachine(
                              newData,
                              this.state.regions
                            );
                          } else {
                            alert('Update on logged in user rejected');
                            return '';
                          }
                          this.setState({ loading: true, data: '' });
                          //this.getUsers();
                          setTimeout(() => {
                            this.getMachines();
                          }, 600);
                        }, 600);
                      }),
                    onRowDelete: oldData =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          if (oldData.email !== this.props.profile.email) {
                            MachineService.deleteOtdrMachine(oldData);
                          } else {
                            alert('Cannot delete currently logged in user');
                            return '';
                          }
                          this.setState({ loading: true, data: '' });
                          setTimeout(() => {
                            this.getMachines();
                          }, 600);
                        }, 600);
                      }),

                    onRowAdd: newData =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          if (newData.email !== this.props.profile.email) {
                            MachineService.addOtdrMachine(
                              newData,
                              this.state.regions
                            );
                          } else {
                            alert('Cannot delete currently logged in user');
                            return '';
                          }
                          this.setState({ loading: true, data: '' });
                          setTimeout(() => {
                            this.getMachines();
                          }, 800);
                        }, 800);
                      }),
                  }
                : {}
            }
          />
        )}
      </Fragment>
    );
  }
}
export default withStyles(styles)(ListOtdrs);
