import axios from 'axios';
import Base64 from 'base-64';
import history from '../../helpers/history';

const login = async values => {
  const url = 'https://splicing.adtel.co.ke/oauth/token';
  const auth = Base64.encode(
    'androidclient:b!x93Vx87Tjx88xf8xbaxdfxd8xd5Jxc7oQbx01x9b'
  );
  await axios
    .post(
      url,
      {},
      {
        params: {
          username: values.email,
          password: values.password,
          grant_type: 'password',
        },
        headers: {
          authorization: `Basic ${auth}`,
        },
      }
    )
    .then(response => {
      const data = response.data;
      localStorage.setItem('token', data.access_token);
      history.push('/');
      return data;
    });
};
const logout = () => {
  localStorage.clear();
  history.push('/login');
};
const isLoggedIn = () => {
  if (localStorage.getItem('token') !== null) {
    return true;
  }
  return false;
};

const hasAdminRights = () => {
  if (
    localStorage.getItem('role') !== 'TECHNICIAN' &&
    localStorage.getItem('role') !== null
  ) {
    return true;
  }
  return false;
};

export { login, logout, isLoggedIn, hasAdminRights };
