import React, { Component, Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import PageStructure from '../../components/PageStructure';
import Spinner from '../../components/Spinner';
import Profile from '../../services/ProfileService';
import ListMachines from './components/ListMachines';
import ListOtdrs from './components/ListOtdrs';
import Title from '../../components/Title';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class Devices extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.handleTabValChange = this.handleTabValChange.bind(this);
    this.state = {
      profile: '',
      loading: true,
      tabVal: 0,
    };
  }

  handleTabValChange(event, newVal) {
    this.setState({ tabVal: newVal });
  }

  getProfile() {
    Profile.getProfile().then(val => {
      if (val !== '') {
        this.setState({
          profile: val,
        });
      }
    });
    const interval = setInterval(() => {
      if (this.state.profile !== '' && this.state.profile !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }
  componentDidMount() {
    this.getProfile();
  }

  render() {
    return (
      <Fragment>
        {!this.state.loading ? (
          <PageStructure page="Devices" user={this.state.profile}>
            <CssBaseline />

            <Tabs
              value={this.state.tabVal}
              indicatorColor="primary"
              textColor="primary"
              aria-label="device selection tabs"
              centered
              onChange={this.handleTabValChange}
              style={{ marginBotton: '10px' }}>
              <Tab label="Splice Machines" />
              <Tab label="OTDR Machines" />
            </Tabs>
            {this.state.tabVal === 0 && (
              <Fragment>
                <Title>Manage Splicing Machines</Title>
                <ListMachines profile={this.state.profile} />
              </Fragment>
            )}

            {this.state.tabVal === 1 && (
              <Fragment>
                <Title>Manage OTDR Machines</Title>
                <ListOtdrs profile={this.state.profile} />
              </Fragment>
            )}
          </PageStructure>
        ) : (
          <Spinner />
        )}
      </Fragment>
    );
  }
}

export default Devices;
