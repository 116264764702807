import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Title from '../../../../components/Title';
import { PieChart, Pie, Sector } from 'recharts';
import Spinner from '../../../../components/Spinner';
import RecordsService from '../../../../services/RecordsService';

const styles = theme => ({
  content: {
    marginLeft: '10px',
    textAlign: 'center',
  },
});

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333">{`Count ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

class DeviceStats extends Component {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/hqnrgxpj/';
  constructor(props) {
    super(props);
    this.onPieEnter = this.onPieEnter.bind(this);
    this.state = {
      activeIndex: 0,
      loading: true,
      deviceData: '',
    };
  }
  onPieEnter(data, index) {
    this.setState({
      activeIndex: index,
    });
  }

  getDeviceStats() {
    RecordsService.getDeviceStats().then(val => {
      if (val !== '') {
        this.setState({
          deviceData: [
            { name: 'Devices in Store', value: val.available },
            { name: 'Field', value: val.issued },
          ],
        });
      }
    });

    const interval = setInterval(() => {
      if (this.state.deviceData !== '' && this.state.deviceData !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }

  componentDidMount() {
    this.getDeviceStats();
  }

  render() {
    return (
      <Fragment>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className={this.props.classes.content}>
            <Title>Device Stats</Title>
            <PieChart width={500} height={300}>
              <Pie
                activeIndex={this.state.activeIndex}
                activeShape={renderActiveShape}
                data={this.state.deviceData}
                cx={250}
                cy={150}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={this.onPieEnter}
              />
            </PieChart>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(DeviceStats);
