import axios from 'axios';
import Auth from '../AuthService';

const getUsers = async () => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/users';
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const deleteUser = async user => {
  const url = `https://splicing.adtel.co.ke/api/users/${user.id}`;
  const token = localStorage.getItem('token');
  await axios
    .delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
};

const editUser = async user => {
  console.log(user);
  const url = `https://splicing.adtel.co.ke/api/users/${user.id}`;
  const token = localStorage.getItem('token');
  await axios
    .put(
      url,
      {
        id: user.id,
        email: user.email,
        firstName: user.fName,
        lastName: user.lName,
        phoneNumber: user.number,
        role:
          user.role === '1'
            ? 'SUPERADMIN'
            : user.role === '2'
            ? 'ADMIN'
            : user.role === '3'
            ? 'MAKER'
            : 'TECHNICIAN',
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
};

const addUser = async user => {
  const url = `https://splicing.adtel.co.ke/api/users`;
  const token = localStorage.getItem('token');
  await axios
    .post(
      url,
      {
        email: user.email,
        firstName: user.fName,
        lastName: user.lName,
        phoneNumber: user.number,
        role:
          user.role === '1'
            ? 'SUPERADMIN'
            : user.role === '2'
            ? 'ADMIN'
            : user.role === '3'
            ? 'MAKER'
            : 'TECHNICIAN',
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });
};

const getTechnicians = async () => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/users/available/technician';
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      params: { role: 'TECHNICIAN' },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

export { getUsers, deleteUser, editUser, addUser, getTechnicians };
