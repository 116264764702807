import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Spinner from '../../../../components/Spinner';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  card: {},
  media: {
    height: '150px',
    width: '100%',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  grid: {
    flexGrow: 1,
    marginTop: '20px',
  },
});

class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.formatData = this.formatData.bind(this);
    this.state = {
      rows: '',
      data: '',
    };
  }

  formatData() {
    const jobs = this.props.jobs;
    const formattedData = [];
    jobs.map(val => {
      formattedData.push(jobs.splice(0, 4));
      return '';
    });
    console.log(formattedData);
    this.setState({ data: formattedData });
  }

  componentDidMount() {
    this.formatData();
  }

  render() {
    return (
      <Fragment>
        {this.state.data === '' ? (
          <Spinner />
        ) : (
          <Grid container className={this.props.classes.grid} spacing={2}>
            {this.state.data.map(vals => {
              return (
                <Grid item xs={12} key={this.state.data.indexOf(vals)}>
                  <Grid
                    container
                    spacing={4}
                    justify={
                      this.state.data.length > 4 ? 'center' : 'flex-start'
                    }>
                    {vals.map(job => {
                      return (
                        <Grid item key={job.id}>
                          <Card className={this.props.classes.card}>
                            <CardActionArea>
                              <CardMedia
                                className={this.props.classes.media}
                                image={job.base64EncryptedImage}
                                title="Job Image"
                              />
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="h2">
                                  Job Details
                                </Typography>
                                <Typography
                                  variant="body2"
                                  component="div"
                                  color="textSecondary">
                                  <p>Technician:{job.technician.email}</p>
                                  <p>
                                    Recorded Splice Count: {job.spliceCount}
                                  </p>
                                  <p>
                                    Device Used:{' '}
                                    {`${job.machine.model} of serial number:${job.machine.serialNumber}`}
                                  </p>
                                  <p>
                                    Date Recorded:
                                    {new Date(job.created).toLocaleString()}
                                  </p>
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Fragment>
    );
  }
}
export default withStyles(styles)(JobDetails);
