import React, { Component, Fragment } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import ForgotPasswordForm from './ForgotPasswordForm';

//services
import Auth from '../../services/AuthService';

const Transition = props => {
  return <Slide {...props} direction="left" />;
};

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.setShowPassword = this.setShowPassword.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.setResetAccepted = this.setResetAccepted.bind(this);
    this.setResetRejected = this.setResetRejected.bind(this);
    this.state = {
      showPassword: false,
      submitted: false,
      error: false,
      errorMessage: '',
      count: 0,
      open: false,
      forgotPasswordDialog: false,
      resetAccepted: false,
      resetRejected: false,
    };
  }

  setResetAccepted() {
    this.setState({ resetAccepted: true });
  }
  setResetRejected(message) {
    this.setState({ resetRejected: true, errorMessage: message });
  }

  openDialog() {
    this.setState({ forgotPasswordDialog: true });
  }
  closeDialog() {
    this.setState({ forgotPasswordDialog: false });
  }

  setShowPassword(e) {
    if (e.target.checked) {
      this.setState({ showPassword: true });
    } else {
      this.setState({ showPassword: false });
    }
  }

  setOpen() {
    this.setState({
      open: true,
      error: true,
      errorMessage: this.props.status.message,
      count: 1,
    });
  }

  handleClose() {
    this.setState({
      open: false,
      count: 0,
      errorMessage: '',
      error: false,
      submitted: false,
      resetAccepted: false,
      resetRejected: false,
    });
    this.props.setStatus({ error: false });
  }

  componentDidUpdate() {
    if (this.state.count === 0) {
      if (this.props.status !== undefined) {
        if (this.props.status.error === true) {
          this.setOpen();
        }
      }
    }
  }

  render() {
    return (
      <Fragment>
        <form
          className={this.props.classes.form}
          onSubmit={this.props.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            label="Email Address"
            autoComplete="email"
            type="email"
            value={this.props.values.email || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            error={
              this.props.touched.email && this.props.errors.email ? true : false
            }
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            name="password"
            label="Password"
            autoComplete="current-password"
            type={this.state.showPassword ? 'text' : 'password'}
            value={this.props.values.password || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            error={
              this.props.touched.password && this.props.errors.password
                ? true
                : false
            }
            autoFocus
          />
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                onChange={e => this.setShowPassword(e)}
              />
            }
            label="Show password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={this.props.classes.submit}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Button variant="text" onClick={this.openDialog}>
                Forgot Password?
              </Button>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={this.state.forgotPasswordDialog}
          onClose={this.closeDialog}
          fullWidth={true}>
          <DialogTitle>Forgot Password?</DialogTitle>
          <DialogContent>
            <ForgotPasswordForm
              classes={this.props.classes}
              onClick={this.closeDialog}
              onBlur={this.setResetAccepted}
              onChange={this.setResetRejected}
            />
          </DialogContent>
        </Dialog>
        <Snackbar
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
          <Alert severity="error" onClose={this.handleClose}>
            {this.state.errorMessage}
          </Alert>
        </Snackbar>

        <Snackbar
          open={this.state.resetRejected}
          autoHideDuration={6000}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
          <Alert severity="error" onClose={this.handleClose}>
            {this.state.errorMessage}
          </Alert>
        </Snackbar>

        <Snackbar
          open={this.state.resetAccepted}
          autoHideDuration={6000}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
          <Alert severity="success" onClose={this.handleClose}>
            Password Reset request recieved. Check your email for details.
          </Alert>
        </Snackbar>
      </Fragment>
    );
  }
}
export default withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('invalid email address')
      .required('required!!'),
    password: Yup.string().required('required!!'),
  }),
  handleSubmit: (values, { setSubmitting, setStatus, setErrors }) => {
    Auth.login(values)
      .then(data => {
        //do nothing upon login
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 401') {
          setErrors({ email: 'invalid', password: 'invalid' });
          setStatus({ error: true, message: 'Invalid username/password' });
          setSubmitting(false);
        } else {
          setStatus({ error: true, message: error.message });
          setSubmitting(false);
        }
      });
  },
})(LoginForm);
