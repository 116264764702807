import React, { Component, Fragment } from 'react';
import MaterialTable from 'material-table';
import Spinner from '../../../components/Spinner';
import MachineService from '../../../services/MachineService';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';

class SelectOtdr extends Component {
  constructor(props) {
    super(props);
    this.getMachines = this.getMachines.bind(this);
    this.state = {
      data: '',
      loading: true,
      selectedMachine: '',
    };
  }

  getMachines() {
    MachineService.getAvailableOtdrMachines().then(val => {
      if (val !== '') {
        const values = [];
        val.map(machine => {
          const entry = {
            id: machine.id,
            model: machine.model,
            serialNumber: machine.serialNumber,
            available: machine.available ? 'In Strore' : 'Field',
            created: new Date(machine.created).toLocaleDateString(),
          };
          values.push(entry);
          return values;
        });
        this.setState({ data: values });
      }
    });

    const interval = setInterval(() => {
      if (this.state.data !== '' && this.state.data !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }

  componentDidMount() {
    this.getMachines();
  }

  render() {
    const columns = [
      { title: 'Model', field: 'model' },
      { title: 'Serial Number', field: 'serialNumber' },
      { title: 'Availability', field: 'available', editable: 'never' },
      { title: 'Created', field: 'created', editable: 'never' },
    ];

    return (
      <Fragment>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <MaterialTable
            title="Available OTDR Machines"
            columns={columns}
            data={this.state.data}
            actions={[
              rowData => ({
                icon:
                  this.props.machine !== '' &&
                  this.props.machine.id === rowData.id
                    ? () => <ToggleOnIcon color="secondary" size="medium" />
                    : () => <ToggleOffIcon color="primary" size="medium" />,
                tooltip: 'Select otdr',
                onClick: (event, rowData) => this.props.onClick(rowData),
              }),
            ]}
          />
        )}
      </Fragment>
    );
  }
}

export default SelectOtdr;
