import axios from 'axios';
import Auth from '../AuthService';

const getProfile = async () => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/profile';
  const token = localStorage.getItem('token');
  await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => {
      data = res.data;
      localStorage.setItem('role', data.role);
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const updateProfile = async newProfile => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/profile';
  const token = localStorage.getItem('token');
  await axios
    .put(
      url,
      {
        id: newProfile.id,
        email: newProfile.email,
        role: newProfile.role,
        phoneNumber: newProfile.phoneNumber,
        firstName: newProfile.fName,
        lastName: newProfile.lName,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      data = res.data;
      return data;
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        alert('session expired');
        Auth.logout();
        return '';
      }
      alert(error.message);
    });

  return data;
};

const deleteAccount = () => {};

const resetPassword = async values => {
  let data = '';
  const token = localStorage.getItem('token');
  const url = 'https://splicing.adtel.co.ke/api/profile/resetPassword';
  await axios
    .put(
      url,
      {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        repeatPassword: values.repeatPassword,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(res => {
      data = res.data;
      return data;
    });

  return data;
};

const forgotPassword = async email => {
  let data = '';
  const url = 'https://splicing.adtel.co.ke/api/forgotPassword';
  await axios
    .put(
      url,
      {},
      {
        params: {
          email: email,
        },
      }
    )
    .then(res => {
      data = res.data;
      return data;
    });

  return data;
};

export {
  getProfile,
  updateProfile,
  deleteAccount,
  resetPassword,
  forgotPassword,
};
