import React, { Component, Fragment } from 'react';
import history from '../../../../helpers/history';
import Spinner from '../../../../components/Spinner';
import Title from '../../../../components/Title';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import RecordsService from '../../../../services/RecordsService';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    overflow: 'auto',
    minWidth: '350px',
  },
});

class RecordList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      loading: true,
    };
  }

  getRecords() {
    RecordsService.getActiveRecords().then(val => {
      if (val !== '') {
        const values = [];
        val.map(record => {
          const entry = {
            id: record.id,
            issuedBy: `${record.issuedBy.firstName} ${record.issuedBy.lastName}`,
            technician: `${record.issuedTo.firstName} ${record.issuedTo.lastName}`,
            dateIssued: new Date(record.issuedDate).toLocaleString(),
            serialNumber: `${record.machine.model} SN:${record.machine.serialNumber}`,
            spliceCount: record.spliceCount,
            returned: record.returned,
          };
          values.push(entry);
          return values;
        });
        this.setState({ data: values });
      }
    });

    const interval = setInterval(() => {
      if (this.state.data !== '' && this.state.data !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }

  componentDidMount() {
    this.getRecords();
  }

  render() {
    const columns = [
      { title: 'Splice Machine', field: 'serialNumber' },
      { title: 'OTDR Machine', field: 'otdr' },
      { title: 'Technician', field: 'technician' },
      { title: 'Issued By', field: 'issuedBy' },
      { title: 'Intial Splice Count', field: 'spliceCount' },
      { title: 'Date Issued', field: 'dateIssued' },
    ];
    return (
      <Fragment>
        <Title>Devices in the Field</Title>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <MaterialTable
            className={this.props.classes.paper}
            title=""
            columns={columns}
            data={this.state.data}
            actions={[
              {
                icon: () => <EditIcon color="primary" />,
                tooltip: 'Assign OTDR Machine',
                onClick: (event, rowData) => {
                  //todo
                },
              },
              {
                icon: () => <MoreHorizIcon color="primary" />,
                tooltip: 'Proceed to view details',
                onClick: (event, rowData) => {
                  history.push(`/record/${rowData.id}`);
                },
              },
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(RecordList);
