import React from 'react';
import { Router, Switch } from 'react-router-dom';
import './App.css';
import history from './helpers/history';

import LoginPage from './content/LoginPage';
import Dashboard from './content/Dashboard';
import Accounts from './content/Accounts';
import Devices from './content/Devices';
import IssueDevice from './content/IssueDevice';
import ReturnDevice from './content/ReturnDevice';
import Report from './content/Report';
import ProfilePage from './content/Profile';
import ProtectedRoute from './helpers/ProtectedRoute';
import AdminProtectedRoute from './helpers/AdminProtectedRoute';
import LoginRoute from './helpers/LoginRoute';
import RecordDetails from './content/RecordDetails';
import MapPage from './content/Map';
import Downloads from './content/Downloads';
import Regions from './content/Regions';

class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <LoginRoute exact={true} component={LoginPage} path="/login" />
          <ProtectedRoute exact={true} component={Dashboard} path="/" />
          <AdminProtectedRoute
            exact={true}
            component={Accounts}
            path="/accounts"
          />
          <AdminProtectedRoute
            exact={true}
            component={Devices}
            path="/devices"
          />
          <AdminProtectedRoute
            exact={true}
            component={IssueDevice}
            path="/issue"
          />
          <ProtectedRoute
            exact={true}
            component={ProfilePage}
            path="/profile"
          />
          <AdminProtectedRoute
            exact={true}
            component={ReturnDevice}
            path="/return"
          />
          <AdminProtectedRoute exact={true} component={Report} path="/report" />

          <AdminProtectedRoute
            exact={true}
            component={RecordDetails}
            path="/record/:id"
          />
          <AdminProtectedRoute
            exact={true}
            component={Regions}
            path="/regions"
          />
          <ProtectedRoute
            exact={true}
            component={MapPage}
            path="/location/:longitude/:latitude"
          />
          <ProtectedRoute
            exact={true}
            component={Downloads}
            path="/downloads"
          />
        </Switch>
      </Router>
    );
  }
}
export default App;
