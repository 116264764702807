import React, { Component, Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import PageStructure from '../../components/PageStructure';
import Spinner from '../../components/Spinner';
import Profile from '../../services/ProfileService';
import UpdateForm from './components/UpdateForm';
import ShowProfile from './components/ShowProfile';
import Title from '../../components/Title';

const styles = theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  paper: {
    marginTop: 10,
    padding: theme.spacing(3, 2),
  },
  image: {
    marginTop: 10,
    padding: theme.spacing(3, 2),
  },
  info: {
    marginLeft: 10,
    color: '#DEB887',
  },
  span: {
    marginRight: 10,
    color: '#2F4F4F',
  },
  formControl: {
    margin: 10,
  },
  icon: {
    marginRight: 5,
  },
  card: {
    marginTop: 10,
    maxWidth: 345,
    padding: theme.spacing(3, 2),
  },
  media: {
    height: 140,
  },
});

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.state = {
      profile: '',
      loading: true,
    };
  }
  getProfile() {
    Profile.getProfile().then(val => {
      if (val !== '') {
        this.setState({
          profile: val,
        });
      }
    });
    const interval = setInterval(() => {
      if (this.state.profile !== '' && this.state.profile !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }
  componentDidMount() {
    this.getProfile();
  }
  render() {
    return (
      <Fragment>
        {!this.state.loading ? (
          <PageStructure page="Profile" user={this.state.profile}>
            <Title>User Profile</Title>
            <Grid container direction="row" spacing={2}>
              <Grid item sm={4}>
                <ShowProfile
                  classes={this.props.classes}
                  user={this.state.profile}
                />
              </Grid>
              <Grid item sm={8}>
                <UpdateForm
                  classes={this.props.classes}
                  user={this.state.profile}
                />
              </Grid>
            </Grid>
          </PageStructure>
        ) : (
          <Spinner />
        )}
      </Fragment>
    );
  }
}
export default withStyles(styles)(ProfilePage);
