import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';

const styles = theme => ({
  leaflet: {
    width: '100%',
    height: '50vh',
    zIndex: 3,
  },
});

class MapSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinates: '',
    };
  }

  render() {
    return (
      <Fragment>
        <div>
          <Map
            className={this.props.classes.leaflet}
            center={
              this.props.jobs.length === 0
                ? [100091, -100989]
                : [this.props.jobs[0].latitude, this.props.jobs[0].longitude]
            }
            zoom={15}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {this.props.jobs.map(job => {
              return (
                <Marker position={[job.latitude, job.longitude]} key={job.id}>
                  <Popup>{new Date(job.created).toLocaleString()}</Popup>
                </Marker>
              );
            })}
          </Map>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(MapSection);
