import React, { Component, Fragment } from 'react';
import history from '../../../helpers/history';
import Spinner from '../../../components/Spinner';
import Title from '../../../components/Title';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import RegionService from '../../../services/RegionsService';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const styles = theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    overflow: 'auto',
    minWidth: '350px',
  },
});

class ListRegions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      loading: true,
    };
  }

  getRecords() {
    RegionService.getRegions().then(val => {
      if (val !== '') {
        const values = [];
        val.map(region => {
          const entry = {
            id: region.id,
            regId: region.regionId,
            regName: region.name,
          };
          values.push(entry);
          return values;
        });
        this.setState({ data: values });
      }
    });

    const interval = setInterval(() => {
      if (this.state.data !== '' && this.state.data !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }

  componentDidMount() {
    this.getRecords();
  }

  render() {
    const columns = [
      { title: 'Region Id', field: 'regId' },
      { title: 'Region Name', field: 'regName' },
    ];
    return (
      <Fragment>
        <Title>Locations</Title>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <MaterialTable
            className={this.props.classes.paper}
            title=""
            columns={columns}
            data={this.state.data}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    if (oldData.email !== this.props.profile.email) {
                      RegionService.editRegion(newData);
                    } else {
                      alert('Update on logged in user rejected');
                      return '';
                    }
                    this.setState({ loading: true, data: '' });
                    //this.getUsers();
                    setTimeout(() => {
                      this.getRecords();
                    }, 800);
                  }, 800);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    if (oldData.email !== this.props.profile.email) {
                      RegionService.deleteRegion(oldData);
                    } else {
                      alert('Cannot delete currently logged in user');
                      return '';
                    }
                    this.setState({ loading: true, data: '' });
                    //this.getUsers();
                    setTimeout(() => {
                      this.getRecords();
                    }, 800);
                  }, 800);
                }),

              onRowAdd: newData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    if (newData.email !== this.props.profile.email) {
                      RegionService.addRegion(newData);
                    } else {
                      alert('Cannot delete currently logged in user');
                      return '';
                    }
                    this.setState({ loading: true, data: '' });
                    setTimeout(() => {
                      this.getRecords();
                    }, 800);
                  }, 800);
                }),
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(ListRegions);
