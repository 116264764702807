import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withFormik } from 'formik';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';

import RecordsService from '../../../../services/RecordsService';

class SubmitForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }
  addCount() {
    this.setState({ count: 1 });
  }
  resetCount() {
    this.setState({ count: 0 });
  }

  handleSubmit(id, spliceCount) {
    RecordsService.returnDevice(id, spliceCount).then(data => {
      if (data !== '') {
        window.location.reload(true);
      }
    });
    this.props.onClick();
  }

  componentDidUpdate() {
    if (this.state.count === 0) {
      if (this.props.status !== undefined) {
        if (this.props.status.data !== undefined) {
          this.addCount();
          this.handleSubmit(
            this.props.recordId,
            this.props.status.data.spliceCount
          );
        }
      }
    }
  }
  render() {
    return (
      <div>
        <form onSubmit={this.props.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="spliceCount"
            name="spliceCount"
            label="Splice Count"
            autoComplete="splice count"
            type="number"
            value={this.props.values.spliceCount || ''}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            error={
              this.props.touchedspliceCount && this.props.errors.spliceCount
                ? true
                : false
            }
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={this.props.classes.submit}
            disabled={this.props.errors.spliceCount ? true : false}>
            Submit
          </Button>
        </form>
      </div>
    );
  }
}
export default withFormik({
  mapPropsToValues: () => ({
    spliceCount: '',
  }),
  validationSchema: Yup.object().shape({
    spliceCount: Yup.number().required(
      'current splice count is required to close a record'
    ),
  }),
  handleSubmit: (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    setStatus({ data: values });
  },
})(SubmitForm);
