import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import UnfoldMoreTwoToneIcon from '@material-ui/icons/UnfoldMoreTwoTone';
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import Auth from '../../services/AuthService';

const StyledMenu = withStyles({
  paper: {
    border: 'none',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const TopBar = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="absolute"
      className={clsx(
        props.classes.appBar,
        props.open && props.classes.appBarShift
      )}>
      <Toolbar className={props.classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={props.onClick}
          className={clsx(
            props.classesmenuButton,
            props.open && props.classes.menuButtonHidden
          )}>
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={props.classes.title}>
          Splice Machines Portal: {props.page}
        </Typography>
        <Typography color="inherit">{props.user.email}</Typography>
        <IconButton color="inherit" onClick={handleClick}>
          <UnfoldMoreTwoToneIcon />
        </IconButton>
      </Toolbar>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem component={Link} to="/downloads">
          <ListItemIcon>
            <GetAppTwoToneIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Downloads" />
        </MenuItem>
        <MenuItem component={Link} to="/profile">
          <ListItemIcon>
            <PersonOutlineTwoToneIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            Auth.logout();
          }}>
          <ListItemIcon>
            <ExitToAppTwoToneIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </StyledMenu>
    </AppBar>
  );
};
export default TopBar;
