import React from 'react';
import { Link } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import PollIcon from '@material-ui/icons/Poll';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AndroidIcon from '@material-ui/icons/Android';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';

import Auth from '../../services/AuthService';

const DrawerBar = props => {
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          props.classes.drawerPaper,
          !props.open && props.classes.drawerPaperClose
        ),
      }}
      open={props.open}>
      <div className={props.classes.toolbarIcon}>
        <IconButton onClick={props.onClick}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <div>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </div>
      </List>
      <Divider />
      <List>
        <div>
          <ListItem
            button
            component={Link}
            to="/accounts"
            disabled={Auth.hasAdminRights() === false ? true : false}>
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Accounts" />
          </ListItem>
        </div>
      </List>
      <Divider />
      <List>
        <div>
          <ListItem
            button
            component={Link}
            to="/regions"
            disabled={Auth.hasAdminRights() === false ? true : false}>
            <ListItemIcon>
              <LocationSearchingIcon />
            </ListItemIcon>
            <ListItemText primary="Regions" />
          </ListItem>
        </div>
      </List>
      <Divider />
      <List>
        <div>
          <ListItem
            button
            component={Link}
            to="/devices"
            disabled={Auth.hasAdminRights() === false ? true : false}>
            <ListItemIcon>
              <DevicesOtherIcon />
            </ListItemIcon>
            <ListItemText primary="Devices" />
          </ListItem>
        </div>
      </List>
      <Divider />
      <List>
        <div>
          <ListItem
            button
            component={Link}
            to="/issue"
            disabled={Auth.hasAdminRights() === false ? true : false}>
            <ListItemIcon>
              <ArrowForwardIcon />
            </ListItemIcon>
            <ListItemText primary="Issue Devices" />
          </ListItem>
        </div>
      </List>
      <Divider />
      <List>
        <div>
          <ListItem
            button
            component={Link}
            to="/return"
            disabled={Auth.hasAdminRights() === false ? true : false}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Returned Device" />
          </ListItem>
        </div>
      </List>
      <Divider />
      <List>
        <div>
          <ListItem
            button
            component={Link}
            to="/report"
            disabled={Auth.hasAdminRights() === false ? true : false}>
            <ListItemIcon>
              <PollIcon />
            </ListItemIcon>
            <ListItemText primary="Generate Report" />
          </ListItem>
        </div>
      </List>
      <Divider />
      <List>
        <div>
          <ListItem button>
            <ListItemIcon>
              <a
                download
                href="https://splicing.adtel.co.ke/api/storage/download/spliceappv1.0.apk"
                target="_blank"
                rel="noopener noreferrer">
                <AndroidIcon color="primary" />
              </a>
            </ListItemIcon>

            <ListItemText
              primary="Download Apk"
              component="a"
              href="https://splicing.adtel.co.ke/api/storage/download/spliceappv1.0.apk"
              download
              target="_blank"
              rel="noopener noreferrer"
            />
          </ListItem>
        </div>
      </List>

      <List className={props.classes.userSection}>
        <div>
          <Divider />
          <ListItem button component={Link} to="/profile">
            <ListItemIcon>
              <AccountCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText secondary={props.user.email} />
          </ListItem>
          <ListItem button onClick={() => Auth.logout()}>
            <ListItemIcon>
              <ExitToAppIcon color="primary" />
            </ListItemIcon>
            <ListItemText secondary="logout" />
          </ListItem>
        </div>
      </List>
    </Drawer>
  );
};
export default DrawerBar;
