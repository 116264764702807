import React, { Component, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ResetPassword from './ResetPassword';
import PersonIcon from '../../../static/person-icon.png';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';

const Transition = props => {
  return <Slide {...props} direction="left" />;
};

class ShowProfile extends Component {
  constructor(props) {
    super(props);
    this.setResetAccepted = this.setResetAccepted.bind(this);
    this.setResetRejected = this.setResetRejected.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      forgotPasswordDialog: false,
      resetAccepted: false,
      resetRejected: false,
      errorMessage: '',
    };
  }

  setResetAccepted() {
    this.setState({ resetAccepted: true });
  }

  setResetRejected(message) {
    this.setState({ resetRejected: true, errorMessage: message });
  }

  openDialog() {
    this.setState({ forgotPasswordDialog: true });
  }
  closeDialog() {
    this.setState({ forgotPasswordDialog: false });
  }

  handleClose() {
    this.setState({
      message: '',
      resetAccepted: false,
      resetRejected: false,
    });
  }

  render() {
    return (
      <Fragment>
        <Card className={this.props.classes.card}>
          <CardActionArea component="div">
            <CardMedia
              className={this.props.classes.media}
              image={PersonIcon}
              title={this.props.user.email}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h2"
                align="center">
                {this.props.user.email}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                align="center">
                <p>Email: {this.props.user.email}</p>
                <p>First Name: {this.props.user.firstName}</p>
                <p>Last Name: {this.props.user.lastName}</p>
                <p>Phone Number: {this.props.user.phoneNumber}</p>
                <p>Role: {this.props.user.role}</p>
                <p>
                  <Button variant="outlined" onClick={this.openDialog}>
                    Reset password
                  </Button>
                </p>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Dialog
          open={this.state.forgotPasswordDialog}
          onClose={this.closeDialog}
          fullWidth={true}>
          <DialogTitle>Reset Password!</DialogTitle>
          <DialogContent>
            <ResetPassword
              classes={this.props.classes}
              onClick={this.closeDialog}
              onBlur={this.setResetAccepted}
              onChange={this.setResetRejected}
            />
          </DialogContent>
        </Dialog>

        <Snackbar
          open={this.state.resetRejected}
          autoHideDuration={6000}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
          <Alert severity="error" onClose={this.handleClose}>
            {this.state.errorMessage}
          </Alert>
        </Snackbar>

        <Snackbar
          open={this.state.resetAccepted}
          autoHideDuration={6000}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
          <Alert severity="success" onClose={this.handleClose}>
            Reset Successful
          </Alert>
        </Snackbar>
      </Fragment>
    );
  }
}

export default ShowProfile;
