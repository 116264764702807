import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import UserService from '../../../services/UserService';
import Spinner from '../../../components/Spinner';

const styles = theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    marginTop: 10,
    overflowX: 'auto',
    minWidth: '350px',
  },
});

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.getUsers = this.getUsers.bind(this);
    this.state = {
      data: '',
      loading: true,
    };
  }

  getUsers() {
    UserService.getUsers().then(val => {
      if (val !== '') {
        const values = [];
        val.map(user => {
          const entry = {
            id: user.id,
            email: user.email,
            fName: user.firstName,
            lName: user.lastName,
            number: user.phoneNumber,
            role:
              user.role === 'SUPERADMIN'
                ? 1
                : user.role === 'ADMIN'
                ? 2
                : user.role === 'MAKER'
                ? 3
                : 4,
          };
          values.push(entry);
          return values;
        });
        if (this.props.profile.role === 'ADMIN') {
          this.setState({
            data: values.filter(val => {
              return Number(val.role) > 1;
            }),
          });
        } else if (this.props.profile.role === 'MAKER') {
          this.setState({
            data: values.filter(val => {
              return Number(val.role) > 2;
            }),
          });
        } else if (this.props.profile.role === 'TECHNICIAN') {
          this.setState({
            data: values.filter(val => {
              return Number(val.role) > 3;
            }),
          });
        } else {
          this.setState({ data: values });
        }
      }
    });

    const interval = setInterval(() => {
      if (this.state.data !== '' && this.state.data !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }

  componentDidMount() {
    this.getUsers();
  }

  render() {
    const columns = [
      { title: 'Email', field: 'email' },
      { title: 'First Name', field: 'fName' },
      { title: 'Last Name', field: 'lName' },
      { title: 'Phone Number', field: 'number' },
      {
        title: 'Role',
        field: 'role',
        lookup:
          this.props.profile.role === 'SUPERADMIN'
            ? { 1: 'SUPERADMIN', 2: 'ADMIN', 3: 'MAKER', 4: 'TECHNICIAN' }
            : this.props.profile.role === 'ADMIN'
            ? { 2: 'ADMIN', 3: 'MAKER', 4: 'TECHNICIAN' }
            : this.props.profile.role === 'MAKER'
            ? { 3: 'MAKER', 4: 'TECHNICIAN' }
            : {},
      },
    ];

    return (
      <Fragment>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <MaterialTable
            title="Active Accounts"
            className={this.props.classes.paper}
            columns={columns}
            data={this.state.data}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    if (oldData.email !== this.props.profile.email) {
                      UserService.editUser(newData);
                    } else {
                      alert('Update on logged in user rejected');
                      return '';
                    }
                    this.setState({ loading: true, data: '' });
                    //this.getUsers();
                    setTimeout(() => {
                      this.getUsers();
                    }, 800);
                  }, 800);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    if (oldData.email !== this.props.profile.email) {
                      UserService.deleteUser(oldData);
                    } else {
                      alert('Cannot delete currently logged in user');
                      return '';
                    }
                    this.setState({ loading: true, data: '' });
                    //this.getUsers();
                    setTimeout(() => {
                      this.getUsers();
                    }, 800);
                  }, 800);
                }),

              onRowAdd: newData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    if (newData.email !== this.props.profile.email) {
                      UserService.addUser(newData);
                    } else {
                      alert('Cannot delete currently logged in user');
                      return '';
                    }
                    this.setState({ loading: true, data: '' });
                    setTimeout(() => {
                      this.getUsers();
                    }, 800);
                  }, 800);
                }),
            }}
            options={{
              actionsColumnIndex: -1,
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(UsersList);
