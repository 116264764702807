import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import PageStructure from '../../components/PageStructure';
import Spinner from '../../components/Spinner';
import Profile from '../../services/ProfileService';
import JobStats from './components/JobStats';
import DeviceStats from './components/DeviceStats';
import JobRecords from './components/JobRecords';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.state = {
      profile: '',
      loading: true,
    };
  }
  getProfile() {
    Profile.getProfile().then(val => {
      if (val !== '') {
        this.setState({
          profile: val,
        });
      }
    });
    const interval = setInterval(() => {
      if (this.state.profile !== '' && this.state.profile !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }
  componentDidMount() {
    this.getProfile();
  }
  render() {
    return (
      <Fragment>
        {!this.state.loading ? (
          <PageStructure page="Dashboard" user={this.state.profile}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item sm>
                <JobStats />
              </Grid>
              <Grid item sm>
                <DeviceStats />
              </Grid>
            </Grid>

            <JobRecords />
          </PageStructure>
        ) : (
          <Spinner />
        )}
      </Fragment>
    );
  }
}
export default Dashboard;
