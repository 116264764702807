import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageStructure from '../../components/PageStructure';
import Spinner from '../../components/Spinner';
import Profile from '../../services/ProfileService';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';

const styles = theme => ({
  leaflet: {
    width: '100%',
    height: '70vh',
    zIndex: 3,
  },
});
class MapPage extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.state = {
      profile: '',
      loading: true,
      lat: this.props.match.params.latitude,
      lng: this.props.match.params.longitude,
      zoom: 20,
    };
  }

  getProfile() {
    Profile.getProfile().then(val => {
      if (val !== '') {
        this.setState({
          profile: val,
        });
      }
    });
    const interval = setInterval(() => {
      if (this.state.profile !== '' && this.state.profile !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }
  componentDidMount() {
    this.getProfile();
  }

  render() {
    const coordinates = [this.state.lat, this.state.lng];
    return (
      <Fragment>
        {!this.state.loading ? (
          <PageStructure page="Dashboard" user={this.state.profile}>
            <div>
              <Map
                center={coordinates}
                zoom={this.state.zoom}
                className={this.props.classes.leaflet}>
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={coordinates}>
                  <Popup>Job Location</Popup>
                </Marker>
              </Map>
            </div>
          </PageStructure>
        ) : (
          <Spinner />
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(MapPage);
