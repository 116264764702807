import React, { Component, Fragment } from 'react';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import Title from '../../../../components/Title';
import Spinner from '../../../../components/Spinner';
import JobService from '../../../../services/JobService';
import JobDetails from '../JobDetails';

const styles = theme => ({
  table: {
    padding: theme.spacing(3, 2),
    marginTop: '50px',
    overflow: 'auto',
    minWidth: '350px',
  },
});

class JobRecords extends Component {
  constructor(props) {
    super(props);
    this.getJobs = this.getJobs.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.state = {
      data: '',
      loading: true,
      openDialog: false,
      rowData: '',
    };
  }

  handleDialogOpen(rowData) {
    this.setState({ openDialog: true, rowData: rowData });
  }

  handleDialogClose() {
    this.setState({ openDialog: false, rowData: '' });
  }

  getJobs() {
    JobService.getJobs().then(val => {
      if (val !== '') {
        const values = [];
        val.map(job => {
          const entry = {
            id: job.id,
            machine: `${job.machine.model} SN:${job.machine.serialNumber}`,
            spliceCount: job.spliceCount,
            technician: `${job.technician.firstName} ${job.technician.lastName}`,
            created: new Date(job.created).toLocaleString(),
            url: job.base64EncryptedImage,
            long: job.longitude,
            lat: job.latitude,
            serialNumber: job.machine.serialNumber,
          };
          values.push(entry);
          return values;
        });
        this.setState({ data: values });
      }
    });

    const interval = setInterval(() => {
      if (this.state.data !== '' && this.state.data !== undefined) {
        this.setState({
          loading: false,
        });
        clearInterval(interval);
      }
    }, 100);
  }

  componentDidMount() {
    this.getJobs();
  }

  render() {
    const columns = [
      { title: 'Machine Used', field: 'machine' },
      {
        title: 'Recorded Splice Count',
        field: 'spliceCount',
        editable: 'never',
      },
      { title: 'Technician', field: 'technician', editable: 'never' },
      { title: 'Date', field: 'created', editable: 'never' },
    ];
    return (
      <Fragment>
        <Title>Recent Recorded Jobs</Title>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <MaterialTable
            className={this.props.classes.table}
            title=""
            columns={columns}
            data={this.state.data}
            actions={[
              {
                icon: RemoveRedEyeIcon,
                tooltip: 'more...',
                onClick: (event, rowData) => this.handleDialogOpen(rowData),
              },
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
        )}

        {this.state.openDialog && (
          <JobDetails
            open={this.state.openDialog}
            onClose={this.handleDialogClose}
            rowData={this.state.rowData}
          />
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(JobRecords);
