import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Copyright from '../Copyright';

const styles = theme => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    //backgroundColor:
    //theme.palette.type === 'light'
    //? theme.palette.grey[200]
    //: theme.palette.grey[800],
    //position: 'absolute',
    //bottom: '0px',
    width: '100%',
  },
});

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer className={this.props.classes.footer}>
        <Copyright />
      </footer>
    );
  }
}
export default withStyles(styles)(Footer);
