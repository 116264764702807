import React, { Component } from 'react';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Copyright from '../../components/Copyright';
import LoginForm from './LoginForm';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class LoginPage extends Component {
  render() {
    return (
      <Container content="main" maxWidth="xs">
        <CssBaseline />
        <div className={this.props.classes.paper}>
          <Typography
            component="h2"
            variant="h6"
            style={{ textAlign: 'center' }}>
            Faiba: Splice Machines Portal
          </Typography>
          <Avatar className={this.props.classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h2" variant="h6">
            Sign in
          </Typography>
          <LoginForm classes={this.props.classes} />
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}
export default withStyles(styles)(LoginPage);
