import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import PinDropIcon from '@material-ui/icons/PinDrop';
import BackspaceIcon from '@material-ui/icons/Backspace';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom';

const styles = theme => ({
  card: {},
  media: {
    height: 300,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          fullWidth={true}>
          <DialogTitle>More....</DialogTitle>
          <DialogContent>
            <Card className={this.props.classes.card}>
              <CardActionArea>
                <CardMedia
                  className={this.props.classes.media}
                  image={this.props.rowData.url}
                  title="Job Image"
                />

                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Job Details
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary">
                    <p>Technician:{this.props.rowData.technician}</p>
                    <p>
                      Recorded Splice Count: {this.props.rowData.spliceCount}
                    </p>
                    <p>Device Used: {this.props.rowData.serialNumber}</p>
                    <p>
                      Date Recorded:{' '}
                      {new Date(this.props.rowData.created).toLocaleString()}
                    </p>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions
                disableSpacing
                className={this.props.classes.actions}>
                <Tooltip title="view job location in map">
                  <Link
                    to={`location/${this.props.rowData.long}/${this.props.rowData.lat}`}>
                    <Fab color="primary">
                      <PinDropIcon />
                    </Fab>
                  </Link>
                </Tooltip>
                <Tooltip title="Cancel">
                  <Fab color="primary" onClick={this.props.onClose}>
                    <BackspaceIcon />
                  </Fab>
                </Tooltip>
              </CardActions>
            </Card>
          </DialogContent>
          <Divider />
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(JobDetails);
